.layout {
  display: flex;
}

.layout_container {
  flex: 6 1;
  background-color: #f7f8fc;
  height: 100vh;
  overflow-y: scroll;
}

.router_contents {
  margin: 1rem 2rem;
}
