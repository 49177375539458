.table_container {
  padding: 2rem 0 0 0;
  background: #ffffff;
  border: 1px solid #dfe0eb;
  border-radius: 8px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 3rem 2rem;
}

.table-header-title h3 {
  font-size: 19px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #252733;
}

.search_filter {
  display: flex;
  gap: 2rem;
}

.table-header-filter {
  display: flex;
}

.MuiInputLabel-outlined.MuiInputLabel-shrink {
  transform: translate(14px, -6px) scale(0.75);
  position: absolute !important;
  left: -25px;
  top: 15px;
}

.MuiOutlinedInput-notchedOutline {
  border-color: rgba(0, 0, 0, 0) !important;
  border-bottom: 1px solid #e0e0e0 !important;
  border-radius: 0 !important;
}

.PrivateNotchedOutline-legendLabelled-5 {
  display: none !important;
}

.MuiTableCell-head {
  color: #9fa2b4 !important;
  font-weight: 400 !important;
  padding: 1rem 2rem;
  padding-left: 2rem !important;
  line-height: 1.5rem;
}

.MuiTableCell-body {
  color: rgba(0, 0, 0, 0.87);
  padding-left: 2rem !important;
}

.ticketNameMail {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.ticketName {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.2px;
  color: #252733;
  font-weight: 500;
}

.ticketMail {
  font-size: 13.5px;
  line-height: 16px;
  letter-spacing: 0.1px;
  font-weight: 400;
  color: #c5c7cd;
}

.priorityCell {
  padding: 0;
  text-align: center;
}

.priorityCell.high p {
  font-weight: 400;
  background-color: #f44336;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.MuiTableRow-root:hover {
  background-color: #dde2ff36;
  cursor: pointer;
}

.MuiTableRow-head:hover {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  line-height: 1.5rem;
  background: white;
}

.priorityCell.normal p {
  font-weight: 400;
  background-color: #29cc97;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.priorityCell.low p {
  font-weight: 400;
  background-color: #fec400;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.priority {
  margin: 0;
  padding: 5px;
  font-size: 12px;
  color: white;
}

.MuiTablePagination-toolbar {
  min-height: 52px;
  padding-right: 2rem !important;
}

@media screen and (max-width: 768px) {
  .table_container {
    background: #fff;
    border: 1px solid #dfe0eb;
    border-radius: 8px;
    padding: 2rem 0 0;
    overflow-x: scroll;
    width: 100vw;
  }

  .table-header {
    align-items: flex-start;
    display: flex;
    /* justify-content: space-between; */
    gap: 2rem;
    padding: 0 2rem 3rem;
    flex-direction: column;
  }

  .MuiTableCell-root {
    font-size: 12px;
    padding: 8px;
    text-align: center;
  }

  .MuiTableCell-body {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ticket-details {
    white-space: normal;
    overflow: auto;
  }

  .priority {
    white-space: normal;
    overflow: auto;
  }
}
