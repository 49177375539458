@import url("https://fonts.cdnfonts.com/css/muli");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* background-color: #f7f8fc; */
}

body {
  font-family: "Muli", sans-serif;
}
