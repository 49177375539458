.sidebars {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #363740;
  top: 0;
  flex: 1;
  padding-top: 2rem;
  transition: all 300ms ease;
}

.logo_mobile {
  display: none;
}

.logo {
  margin: 0 1rem;
}
.logo_img {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
}

.logo_img > img {
  width: 15%;
}

.logo_img > h3 {
  color: #a4a6b3;
  font-size: 1rem;
}

.menu {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-left: 1rem;
  height: 2.5rem;
  position: relative;
  transition: all 300ms ease;
  cursor: pointer;
}

.menuLists {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: #a4a6b3;
}

.menuLists > span {
  font-size: 15px;
  font-weight: 400;
}

.menu_icons {
  width: 20px;
}

.newspaper {
  border-bottom: 1px solid #ccc;
  margin: 8px 0;
}

.active {
  background: #9fa2b41a;
  margin-left: 1rem;
  color: #dde2ff;
  border-left: 3px solid #dde2ff;
  margin-left: 0;
  padding-left: 1rem;
  height: 55px;
  font-weight: 600;
}

.active > .menuLists {
  color: #dde2ff;
  font-weight: 500;
}

.divider {
  height: 0.5px;
  background-color: #585858;
  margin: 10px 0;
}

.bars {
  display: none;
  position: absolute;
  width: 2rem;
}

@media screen and (max-width: 1200px) {
  .sidebars {
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    flex: 0.5;
    padding-top: 2rem;
    transition: all 300ms ease;
  }

  .menuItem > span {
    display: none;
  }

  .logo_img > h3 {
    display: none;
  }

  .logo {
    display: block;
    width: 90%;
    margin-bottom: 3rem;
  }

  .logo_img > img {
    width: 55% !important;
  }

  .menu {
    padding-top: 0;
  }

  .active {
    background: #9fa2b41a;
    margin-left: 1rem;
    color: #dde2ff;
    border-left: 3px solid #dde2ff;
    margin-left: 0;
    padding-left: 1rem;
    height: 55px;
    font-weight: 600;
  }

  .active > .menuLists {
    color: #dde2ff;
    font-weight: 500;
  }

  .menuItem {
    display: flex;
    align-items: center;
    gap: 1rem;
    height: 2.5rem;
    position: relative;
    transition: all 300ms ease;
    cursor: pointer;
  }

  .menuLists {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #a4a6b3;
  }

  .menuLists > span {
    display: none;
  }

  .menu_icons {
    width: 20px;
  }
}

@media screen and (max-width: 768px) {
  .sidebars {
    position: fixed !important;
    z-index: 9;
    width: 55%;
    height: 100%;
    padding-top: 2rem;
  }

  .menuItem > span {
    display: block;
    color: white;
  }

  .menuLists > span {
    display: block;
  }

  .logo_img > h3 {
    display: block;
  }

  .logo {
    display: block;
    width: 90%;
    margin-bottom: 3rem;
  }

  .logo_img > img {
    width: 15% !important;
  }

  .bars {
    display: flex;
    padding: 5px;
    position: fixed;
    width: 2rem;
    height: 2rem;
    top: 1.8rem !important;
    z-index: 10;
    border: 1.6px solid #3637405e;
    background: #f7f8fc;
    color: grey;
    font-size: 1.6rem;
    border-radius: 4px;
    transition: all 300ms ease;
  }
}
